'use strict'
function insertModelData(id){
  var model = MODELS.find(function(m) {
    return m.id === id;
  });
  $('#model-category').text(model.category);
  $('#model-title').text(model.title);
  //$('#model-embed').attr('src', model.embedUrl);
  $('#model-embed')[0].contentWindow.location.replace(model.embedUrl);

  fillUpModelData(model.properties, '#model-description', '#dialog-list-wrapper');
}

function clearDialog() {
  $('#model-category').text('');
  $('#model-title').text('');
  $('#model-embed').attr('src', '');
  $('#model-description').text('');
  $('#dialog-list-wrapper .dialog-list').remove();
}

function registerDialogOpenEvent() {
  $(window).on('hashchange', function (e) {
    if (window.location.hash != "#dialog") {
        $('.modal').modal('hide');
    }
  });

  $('.modal').on('show.bs.modal', function (e) {
    history.pushState('', document.title, "#dialog");
  });

  $('.modal').on('hidden.bs.modal', function (e) {
    if (window.location.hash == "#dialog") {
      removeLocationHash();
    }
  });

  $('#dialog').on('show.bs.modal', function (e) {
    insertModelData($(e.relatedTarget).data('modelId'));
  });

  $('#dialog').on('hidden.bs.modal', function (e) {
    clearDialog();
  });
}

function removeLocationHash() {
  var noHashURL = window.location.href.replace(/#.*$/, '');
  history.replaceState('', document.title, noHashURL) 
}

function createCategory(categoryName, isActive) {
    var div = $('<div class="tab-pane fade in"></div>').uniqueId();
    var divTile = $('<div class="tile-models"></div>');
    $(div).append(divTile);
    $("#model-gallery-tab-content").append(div);

    var a = $('<a data-toggle="tab"></a>').attr('href', "#"+$(div).attr('id')).text(categoryName);
    var li = $("<li></li>").append(a);
    $("#model-gallery-filter-tab").append(li);

    if (isActive) {
      $(a).addClass('active');
      $(div).addClass('active').addClass('show');
    }
    return $(divTile);
}

function addToCategory(categoryContainer, item) {
    $(categoryContainer).append(item);
}

function insertTiles() {
    var templateItem = $.trim($('#tile-template-item').html());
    var allTabTitle = $('#tile-template-item').attr('data-all-tab-title');

    var categories = { "all" : createCategory(allTabTitle, true) }

    MODELS.forEach(function (m) {
        var item = templateItem.replace(/{{bgUrl}}/, m.thumbnail).replace(/{{modelId}}/, m.id);

        if (!categories[m.category]) {
            categories[m.category] = createCategory(m.category);
        }

        addToCategory(categories["all"], item);
        addToCategory(categories[m.category], item);
    });
}

$(document).ready(function() {
    insertTiles();
    registerDialogOpenEvent();

    var hash = window.location.hash;
    hash && hash.startsWith('#ui-id') && $('#model-gallery-filter-tab a[href="' + hash + '"]').tab('show');
});
