'use strict'

$(function () { 
  AOS.init();

  $('#menu').slicknav({
    label: '',
    closeOnClick: true,
    prependTo: '#page-header-menu',
    removeClasses: false,
    duration: 500,
  });

  $(window).on('scroll', function () {
    $('#menu').slicknav('close');
  });

  $('.scroll-down').on('click', function () {
    $('html, body').animate({ scrollTop: $(window).height() }, 1000);
    return false;
  });

  $('.back-to-top').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 1500);
    return false;
  });

  $('.video-switch').on('click', function () {
    var $th = $(this);
    var $id = $($th.attr('href'));

    $('.video-switcher').removeClass('show');
    $id.addClass('show');

    $('.video-switch').removeClass('active');
    $th.addClass('active');

    return false;
  });

  $('.read-more').on('click', function () {
    var $th = $(this);
    $th.prev('.read-more-wrapper').toggleClass('show');
    
    return false;
  });

  function setVideoSwitch(videoSwitchId)
  {
    var $th = $(videoSwitchId);
    var $id = $($th.attr('href'));

    $('.video-switcher').removeClass('show');
    $id.addClass('show');

    $('.video-switch').removeClass('active');
    $th.addClass('active');
  }

  var hour = new Date().getHours();
  if (hour >= 7 && hour <= 18) {
    setVideoSwitch("#video-day-switch");
  } else {
    setVideoSwitch("#video-night-switch");
  }
  
});

(function($, document, window, viewport) {
  var activeMenuItem = $("#menu").attr('data-active-menu');
  $(activeMenuItem).addClass('active');

  viewport.breakpoints = {
    'xs': $('<div class="device-v-xs"></div>'),
    'sm': $('<div class="device-v-sm"></div>'),
    'md': $('<div class="device-v-md"></div>'),
    'lg': $('<div class="device-v-lg"></div>')
  };

  var initTimeline = function (_visibleItems) {
    timeline(document.querySelectorAll('.timeline'), {
      forceVerticalMode: 100,
      mode: 'horizontal',
      visibleItems: _visibleItems
    });
  }

  var initReactiveTimeline = function () {
    var visibleItems = 1; 
    if ( viewport.is('xs') ) {
      visibleItems = 1;
    } 
    if ( viewport.is('sm') ) {
      visibleItems = 2;
    } 
    if ( viewport.is('md') ) {
      visibleItems = 3;
    }
    if ( viewport.is('lg') ) {
      visibleItems = 3;
    }
    initTimeline(visibleItems);
  }

  $(document).ready(function() {
    initReactiveTimeline();

    $(window).resize(
      viewport.changed(initReactiveTimeline)
    );
  });
})(jQuery, document, window, ResponsiveBootstrapToolkit);

function playPause(vw) {
  var videoWrapper = $(vw);
  var video = $(videoWrapper).children('video').get(0);
  if (video.paused) {
    video.play(); 
    $(videoWrapper).addClass("video-play");
  }
  else {
    video.pause();
    $(videoWrapper).removeClass('video-play');
  }  
}