'use strict'
$(function () {
  // slider
  var slickOpts = {
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: 'swing', // see http://api.jquery.com/animate/
    speed: 700,
    dots: true,
    fade: true,
    customPaging: function (slick, index) {
      return '<a>' + (index + 1) + '</a>';
    }
  };
  $('.carousel').slick(slickOpts);

  $('.carousel-next-link').on('click', function () {
    var carouselId = $(this).attr('href');

    $(carouselId).slick('slickNext');

    return false;
  });
});